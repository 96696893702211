<template>
  <v-stepper v-model="actionParams.wizardStep">
    <v-stepper-header>
      <v-stepper-step :complete="actionParams.wizardStep > 1" step="1">
        {{ $t("STEP") }} 1
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step :complete="actionParams.wizardStep > 2" step="2">
        {{ $t("STEP") }} 2
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step :complete="actionParams.wizardStep > 3" step="3">
        {{ $t("STEP") }} 3
      </v-stepper-step>
      <v-divider></v-divider>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <StepStockActionSelectStoreProduction
          :firstLoader.sync="tempFirstLoader"
          :doGetItems.sync="doGetItems"
          :wizardStep.sync="actionParams.wizardStep"
          :items.sync="items"
          :selectedStore.sync="selectedStore"
          :isDough.sync="isDough"
        >
        </StepStockActionSelectStoreProduction>
      </v-stepper-content>
      <v-stepper-content step="2">
        <StepStockActionSetItems
          :firstLoader.sync="tempFirstLoader"
          :wizardStep.sync="actionParams.wizardStep"
          :globalInternalDoc.sync="globalInternalDoc"
          :notes.sync="notes"
          :items.sync="items"
          :headers="headers"
          :selectedItems="selectedItems"
          :selectedItemsTotal="selectedItemsTotal"
          :store="selectedStore"
          :stockActionTypeId="stockActionTypeId"
          :productionData="{
            isDough: isDough,
            mixer: mixer,
            tempRemainingMixerQuantity: tempRemainingMixerQuantity
          }"
          :tempRemainingMixerQuantity="tempRemainingMixerQuantity"
          :showTotalPrice="false"
        >
        </StepStockActionSetItems>
        <v-alert
          v-if="
            isDough == true &&
              (mixer.mixerQuantity == 0 ||
                tempRemainingMixerQuantity > 5 ||
                tempRemainingMixerQuantity < -5)
          "
          class="mt-5"
          border="top"
          color="red lighten-2"
          dark
        >
          Наличността на Миксер е 0 или произвеждате грешно количество теста,
          които надхвърлят позволените норми. Проверете внимателно. Ако сте
          сигурни, но все още не може да продължите, моля да се свържете с
          администратор.
        </v-alert>

        <b-row v-if="isDough && mixer">
          <b-col sm>
            <div class="mt-5 pt-4  alert alert-info">
              <h2>Налично количество Миксер: {{ mixer.mixerQuantity }}</h2>
              <h2>
                Временно използвано количество Миксер:
                {{ tempUserMixerQuantity | formatUnitValue }}
              </h2>
              <h2>
                Оставащо количество Миксер:
                {{ tempRemainingMixerQuantity | formatUnitValue }}
              </h2>
            </div>
          </b-col>
        </b-row>
      </v-stepper-content>
      <v-stepper-content step="3">
        <StepStockActionPreview
          :firstLoader.sync="tempFirstLoader"
          :doGetItems.sync="doGetItems"
          :wizardStep.sync="actionParams.wizardStep"
          :globalInternalDoc.sync="globalInternalDoc"
          :notes.sync="notes"
          :selectedItems="selectedItems"
          :headers="headers"
          :selectedItemsTotal="selectedItemsTotal"
          :selectedStore.sync="selectedStore"
          :stockActionTypeId="stockActionTypeId"
          :showTotalPrice="false"
        >
        </StepStockActionPreview>

        <b-row v-if="isDough && mixer">
          <b-col sm>
            <div class="mt-5 pt-4  alert alert-info">
              <h2>Налично количество Миксер: {{ mixer.mixerQuantity }}</h2>
              <h2>
                Временно използвано количество Миксер:
                {{ tempUserMixerQuantity | formatUnitValue }}
              </h2>
              <h2>
                Оставащо количество Миксер:
                {{ tempRemainingMixerQuantity | formatUnitValue }}
              </h2>
            </div>
          </b-col>
        </b-row>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>
<script>
import StepStockActionSelectStoreProduction from "@/modules/stock/components/actions/stockProduction/StepStockActionSelectStoreProduction.vue";
import StepStockActionSetItems from "@/modules/stock/components/actions/StepStockActionSetItems.vue";
import StepStockActionPreview from "@/modules/stock/components/actions/StepStockActionPreview.vue";
import { FETCH_MIXER } from "@/modules/stock/store/stock.module";

import _ from "lodash";
export default {
  name: "StockProductionStepper",
  inheritAttrs: false,
  props: {
    firstLoader: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isDough: false,
      mixer: null,
      stockActionTypeId: 7,
      doGetItems: true,
      actionParams: {
        wizardStep: 1
      },
      globalInternalDoc: {
        visible: true,
        num: "",
        date: "",
        totalPrice: "0.00"
      },
      notes: "",
      items: [],
      selectedStore: {},
      headers: [
        {
          text: this.$t("ITEM"),
          align: "start",
          filterable: true,
          value: "name"
        },
        {
          text: this.$t("REQUESTED"),
          value: "stockActionItem.requestedUnitValue",
          sortable: true
        },
        {
          text: this.$t("CURRENT"),
          value: "stockActionItem.currentUnitValue",
          sortable: true
        },
        {
          text: this.$t("PRODUCTION"),
          value: "stockActionItem.packageValue",
          sortable: true
        },
        {
          text: this.$t("PRODUCTION"),
          value: "stockActionItem.unitValue",
          sortable: true
        },
        {
          text: this.$t("LOT"),
          value: "stockActionItem.lot",
          sortable: true
        },
        {
          text: this.$t("LOT_EXP_DATE"),
          value: "stockActionItem.lotExpDate",
          sortable: true
        }
      ]
    };
  },
  components: {
    StepStockActionSelectStoreProduction,
    StepStockActionSetItems,
    StepStockActionPreview
  },
  mounted() {
    this.getMixer();
  },
  computed: {
    tempFirstLoader: {
      get: function() {
        return this.firstLoader;
      },
      set: function(newValue) {
        this.$emit("update:firstLoader", newValue);
      }
    },
    selectedItems() {
      let vm = this;
      let selectedItems = [];
      if (_.size(vm.items) > 0) {
        vm.items.forEach(category => {
          let filteredItems = category.items.filter(function(item) {
            return item.stockActionItem.unitValue > 0;
          });
          selectedItems = [...selectedItems, ...filteredItems];
        });
      }
      return selectedItems;
    },
    selectedItemsTotal() {
      let vm = this;
      let totalPrice = 0;
      vm.selectedItems.forEach(item => {
        let itemTotalPrice = item.stockActionItem.totalPrice;
        // let transportPrice = item.stockActionItem.transportPrice;
        totalPrice += parseFloat(itemTotalPrice);
      });
      return this.$options.filters.formatBalance(totalPrice);
    },
    tempUserMixerQuantity() {
      // Calculate the selected quantity by summing the product of unitValue and ingredientUnitValue
      return this.selectedItems.reduce((total, item) => {
        return (
          total +
          item.stockActionItem.unitValue * this.getIngredientUnitValue(item)
        );
      }, 0);
    },
    tempRemainingMixerQuantity() {
      // Calculate the remaining quantity by subtracting tempUserMixerQuantity from initialQuantity
      return this.mixer.mixerQuantity - this.tempUserMixerQuantity;
    }
  },
  watch: {
    selectedItems: {
      handler: function(newValue, oldValue) {
        this.globalInternalDoc.totalPrice = newValue
          .reduce(
            (sum, item) => sum + parseFloat(item.stockActionItem.totalPrice),
            0
          )
          .toFixed(6);
      },
      deep: true
    },
    "actionParams.wizardStep": function(newStep, oldStep) {
      if (newStep === 1) {
        // Call your method here
        this.getMixer();
      }
      // If you want to do nothing for other steps, you can skip the 'else' part.
    }
  },
  methods: {
    getMixer() {
      let vm = this;
      let apiParams = "";
      this.$store
        .dispatch(FETCH_MIXER, apiParams)
        .then(data => {
          vm.mixer = data.data;
        })
        .catch(response => {
          console.log(response);
        });
    },
    getIngredientUnitValue(selectedItem) {
      // Find the ingredient with id 1 MIXER ID (CHANGE IT IF IS NOT 1) and return its ingredientUnitValue
      const ingredientMixer = selectedItem.ingredients.find(
        ingredient => ingredient.ingredientId === 1
      );
      return ingredientMixer ? ingredientMixer.ingredientUnitValue : 0;
    },

    updateWizardStep(step) {
      let vm = this;
      vm.actionParams.wizardStep = step;
    }
  }
};
</script>
