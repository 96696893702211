<template>
  <div>
    <b-row>
      <b-col :lg="'auto'">
        <b-form-group
          v-bind:id="`active-group`"
          v-bind:label="$t('IS_DOUGH')"
          v-bind:label-for="`active`"
        >
          <FormSwitch
            :id="`isDough`"
            type="checkbox"
            :name="`isDough`"
            v-model="tempIsDough"
          ></FormSwitch>
        </b-form-group>
      </b-col>
      <b-col>
        <v-autocomplete
          hide-details
          v-if="_.size(stores) > 0"
          :items="stores"
          :label="$t('STORE')"
          item-text="name"
          item-value="id"
          clearable
          v-model="selectedStoreId"
          v-on:change="doSelectStore"
        ></v-autocomplete>
      </b-col>
    </b-row>
    <b-button
      :disabled="selectedStoreId == null"
      class="mr-3"
      variant="primary"
      @click="goNextStep()"
      >{{ $t("CONTINUE") }}</b-button
    >
  </div>
</template>
<script>
import FormSwitch from "@/view/content/forms/components/FormSwitch.vue";
import { FETCH_STORES_WAREHOUSES } from "@/core/services/store/shared_store.module";
import { FETCH_ITEMS_BY_STORE_PRODUCTION } from "@/modules/stock/store/stock.module";

import { mapGetters, mapState } from "vuex";

import _ from "lodash";
export default {
  name: "StepStockActionSelectStoreProduction",
  inheritAttrs: false,
  props: {
    isDough: {
      type: Boolean,
      default: false
    },
    firstLoader: {
      type: Boolean,
      default: true
    },
    items: {
      type: Array,
      default: function() {
        return [];
      }
    },
    doGetItems: {
      type: Boolean,
      default: true
    },
    wizardStep: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      stores: [],
      selectedStoreId: null
    };
  },
  components: { FormSwitch },
  mounted() {
    let vm = this;
    vm.getStores();
  },
  computed: {
    ...mapGetters(["isLoadingStock", "isLoadingSharedStore", "currentUser"]),
    ...mapState({
      errors: state => state.auth.errors
    }),
    tempFirstLoader: {
      get: function() {
        return this.firstLoader;
      },
      set: function(newValue) {
        this.$emit("update:firstLoader", newValue);
      }
    },
    tempItems: {
      get: function() {
        return this.items;
      },
      set: function(newValue) {
        this.$emit("update:items", newValue);
      }
    },
    tempIsDough: {
      get: function() {
        return this.isDough;
      },
      set: function(newValue) {
        this.$emit("update:isDough", newValue);
      }
    },
    tempDoGetItems: {
      get: function() {
        return this.doGetItems;
      },
      set: function(newValue) {
        this.$emit("update:doGetItems", newValue);
      }
    },
    selectedStore() {
      let vm = this;
      return _.find(vm.stores, { id: vm.selectedStoreId });
    },
    storesParams() {
      let storesParams = {};
      return storesParams;
    },
    itemsParams() {
      let vm = this;
      let itemsParams = {
        storeId: vm.selectedStoreId,
        isDough: vm.tempIsDough
      };
      return itemsParams;
    }
  },
  watch: {
    storesParams(value) {
      let vm = this;
      vm.getStores();
    }
  },
  methods: {
    getStores() {
      let vm = this;
      let params = this.storesParams;
      let apiParams = vm.transformUrlParams(params);
      this.$store
        .dispatch(FETCH_STORES_WAREHOUSES, apiParams)
        .then(data => {
          this.$set(vm, "stores", data.data);
          vm.tempFirstLoader = false;
          if (_.size(data.data) == 1) {
            //Ако е само един магазин, да вземе първия, че да не се налага да избират 1 от 1
            this.$set(vm, "selectedStoreId", data.data[0].id);
            vm.doSelectStore();
          } else {
            //Ако е някой админ и управлява повече от 1 магазин, да може да избира между всички негови
            this.$set(vm, "selectedStoreId", null);
          }
        })
        .catch(response => {
          console.log(response);
        });
    },
    getItems() {
      let vm = this;
      let params = this.itemsParams;
      let apiParams = vm.transformUrlParams(params);
      this.$store
        .dispatch(FETCH_ITEMS_BY_STORE_PRODUCTION, apiParams)
        .then(data => {
          vm.tempItems = data.data;
          vm.tempDoGetItems = false; //prevent reload same items if selectStore is not changed
          this.updateWizardStep(2);
        })
        .catch(response => {
          console.log(response);
        });
    },
    doSelectStore() {
      let vm = this;
      vm.tempDoGetItems = true;
      this.$emit("update:selectedStore", vm.selectedStore);
    },
    goNextStep() {
      let vm = this;
      this.getItems();
    },
    updateWizardStep(wizardStep) {
      this.$emit("update:wizardStep", wizardStep);
    },
    transformUrlParams(params) {
      let apiParams =
        "?" +
        Object.keys(params)
          .map(key => key + "=" + params[key])
          .join("&");
      return apiParams;
    },
    handleInput(prop, value) {
      this.$emit("stepperInput", {
        prop: prop,
        value: value
      });
    }
  }
};
</script>
