<template>
  <div>
    <KTCard ref="preview" v-bind:title="'Edit'">
      <template v-slot:title>
        <h3 class="card-label">
          <router-link :to="{ name: 'stock-scrapping' }">{{
            $t("STOCK_PRODUCТION")
          }}</router-link>
        </h3>
      </template>

      <template v-slot:toolbar>
        <div class="card-toolbar">
          <router-link
            v-permission="['stock-purchase-orders-show']"
            :to="{
              name: 'stock-purchase-order-production'
            }"
            class="btn btn-light-primary font-weight-bolder mr-2"
            ><i class="flaticon2-list"></i>
            {{ $t("STOCK_PURCHASE_ORDER_PRODUCTION") }}</router-link
          >
        </div>
      </template>
      <template v-slot:body>
        <b-form>
          <StockProductionStepper
            v-show="!firstLoader"
            :firstLoader.sync="firstLoader"
          >
          </StockProductionStepper>
          <SkeletonLoaderMain :visible="firstLoader"></SkeletonLoaderMain>
          <v-overlay
            :value="
              !firstLoader &&
                (isLoadingStock == true || isLoadingSharedStore == true)
            "
          >
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </b-form>
      </template>
      <template v-slot:foot>
        <div
          v-show="_.size(errors) > 0"
          role="alert"
          class="alert alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>
      </template>
    </KTCard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import StockProductionStepper from "@/modules/stock/components/StockProductionStepper.vue";

import KTCard from "@/view/content/Card.vue";
import { mapGetters, mapState } from "vuex";

export default {
  name: "StockProduction",
  mixins: [],
  data() {
    return {
      errors: {},
      firstLoader: true
    };
  },
  components: {
    KTCard,
    StockProductionStepper
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Stock Production", route: { name: "stock-production" } }
    ]);
  },
  computed: {
    ...mapGetters(["isLoadingStock", "isLoadingSharedStore", "currentUser"]),
    ...mapState({
      errors: state => state.auth.errors
    })
  },
  methods: {}
};
</script>
